import React from 'react'
import PropTypes from 'prop-types'
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import firebase from 'gatsby-plugin-firebase'
import fetch from 'cross-fetch'

const BEARER_TOKEN_PREFIX = 'Bearer '

const ApolloProviderWrapper = ({ children, ssr }) => {
  // need to provide a fetcher for server side rendering (ssr)
  const apolloHttpLink = createHttpLink({
    uri: process.env.GATSBY_FIREBASE_GATEWAY_FUNCTION_URL,
    ...(ssr && { fetch }),
  })

  const apolloAuthLink = setContext(async (_, { headers }) => {
    // TODO: Update services to support using 'Bearer {token}' value instead of just bare token.
    const token = firebase.auth().currentUser
      ? `${await firebase.auth().currentUser.getIdToken()}`
      : ''

    return {
      headers: {
        ...headers,
        Authorization: BEARER_TOKEN_PREFIX + token,
      },
    }
  })

  const client = new ApolloClient({
    link: apolloAuthLink.concat(apolloHttpLink),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

ApolloProviderWrapper.defaultProps = {
  ssr: false,
}

ApolloProviderWrapper.propTypes = {
  ssr: PropTypes.bool,
}

export default ApolloProviderWrapper
