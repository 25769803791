const English = {
  landingSection: {
    heading1: 'Bored with your online classroom?',
    heading2: 'Meet on the beach instead.',
    paragraph1:
      'Banish the boredom and keep your students engaged on a virtual beach, or more than 40 other stunning and realistic locations in the Metaverse.',
    paragraph2: 'SEE OUR HAWAII VIRTUAL BEACH EXAMPLE!',
    couldntFindMeeting: "Couldn't find the meeting you're trying to join.",
    pleaseSignIn: 'Please sign in to create a meeting',
    error: 'An error has ocurred.',
    youtubeIdHawaii: 'prp7Nq-uum4',
    youtubeTitleHawaii:
      'Let the Adventure Begin With 3Dmeet | Virtual Meeting Platform',
    altImageScreen: '3Dmeet Screen',
  },
  aboutSection: {
    everyDevice: 'Every Device.',
    everyWalkOfLife: 'Every Walk of Life.',
    paragraph1:
      "No matter who you are or what device you use we've got you covered. Intuitive and easy, children to adults of any age connect effortlessly.",
    learnMore: 'Learn more',
    heading2: 'Education has never been this much fun!',
    subheading2:
      'Social · Interactive · Easy · Engaging · A Breath of Fresh Air',
    leftFeatureListHeading: 'Yeah, we do all that.',
    microphoneAndCamera: 'Microphone and Camera Sharing',
    screenSharing: 'Screen Sharing',
    chat: 'Chat',
    fileShare: 'File Share',
    rightFeatureListHeading: 'And we do this too!',
    explore: 'Explore over 30 virtual environments',
    customizeAvatar: 'Customize your own character',
    viewboards: 'Present video and powerpoints in-world',
    voiceLocation: 'Nearby or global voice capability',
    hostPermissions: 'Special features for hosts',
    streaming: 'Streaming capability - NEW',
    youtubeIdLearnMore: 'XYf2FKtYBZs',
    youtubeTitleLearnMore: '3Dmeet - The Complete Conferencing Solution',
    altImagePeople: 'Multiple People',
    altImageComputer: 'Computer',
    altCheckmark: 'Check mark',
  },
  callToActionSection: {
    heading1: 'Feel the joy of socializing in your online classroom!',
    tryButton: 'Try 3Dmeet Now',
    altImageGirl: 'Happy girl',
  },
  pricingPlans: {
    tierFree: 'FREE',
    tierDescriptionFree: 'This is a Free Conferencing Plan',
    tierPriceFree: 'FREE',
    virtualWorldsWithParticipants:
      '{{worlds}} Virtual Worlds with a maximum of {{participants}} participants',
    virtualWorlds: '{{worlds}} Virtual Worlds for training and education',
    tierFeaturesFree: [
      '{{virtualWorlds}}',
      'Desktop and mobile capability',
      'Customizable characters, camera, screenshare, and more',
      'Live text-based chat',
      'Multimedia viewboards',
      'Calls limited to 40 minutes',
    ],
    getStarted: 'GET STARTED',
    tierPro: 'PRO',
    tierDescriptionPro: 'This is a Pro Conferencing Plan',
    tierFeaturesPro: [
      'Features everything from FREE, plus:',
      '{{virtualWorlds}}',
      'Unlimited Calls',
      'Proximity-based voice',
      'Customizable URL',
      'Ability to record session',
    ],
    monthAnnual: '/mo ANNUALLY',
    tierMonthPricePro: 'or {{price}} monthly',
    tierAnnualPricePro: '{{price}} ANNUALLY',
    tierAnnualSavingsPro: 'Save {{discount}}% by purchasing annually',
    manage: 'MANAGE SUBSCRIPTION',
    buyAnnual: 'BUY ANNUAL',
    buyMonthly: 'BUY MONTHLY',
    tierPremium: 'PREMIUM',
    tierDescriptionPremium: 'This is a Premium Conferencing Plan',
    tierFeaturesPremium: [
      '{{virtualWorlds}}',
      'Up to 30 participants per session',
      'Unlimited meetings (up to 5 concurrent sessions)',
      'Customer service support and training',
      'Branding and customization with customer logos and banners',
      'Proximity-based voice',
      'Customizable URL',
      'Ability to record session',
    ],
    tierMonthPricePremium: '{{price}}/mo',
    tierAnnualPricePremium: ' {{price}}/yr',
    tierAnnualSavingsPremium: 'Save {{discount}}% by purchasing annually',
    tierEvents: 'INSTITUTION',
    tierFeaturesEvents: [
      'Features everything from PREMIUM, plus:',
      'Host institution wide events and educational forums',
      'Unlimited number of sessions',
      'Stream keynote speakers and celebrate achievements with our live Youtube streaming feature',
      'Contact 3Dmeet support for pricing',
    ],
    contactSales: 'CONTACT SALES',
    error: 'An error has occurred',
    unlimitedParticipants: 'unlimited',
  },
  newMeetingButton: {
    newMeeting: 'New Meeting',
  },
  newMeetingOptionsModal: {
    createMeeting: 'Create a meeting for later',
    startMeeting: 'Start an instant meeting',
    customMeeting: 'Create a custom meeting',
  },
  joinMeetingForm: {
    enterCode: 'Enter Code or Link',
    button: 'Join',
  },
  footer: {
    allRightsReserved: 'All Rights Reserved',
    codeOfConduct: 'Code of Conduct',
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
  },
  navbar: {
    pricing: 'Pricing',
    account: 'Account',
    signIn: 'Sign In',
  },
  account: {
    title: 'Account',
    heading: 'Account',
    loading: 'Loading',
    welcome: 'Welcome {{name}}',
    logout: 'logout',
    joinMeetingLabel: 'Join a meeting',
    joinMeetingButton: 'Join',
    joinMeetingInput: 'Enter code',
    startMeetingLabel: 'Start a new meeting',
    manageAccountLabel: 'Manage account',
    manageAccountButton: 'Account',
    invalidMeetingError: 'An error has ocurred.',
    genericError: "Couldn't find the meeting you're trying to join.",
  },
  paymentSuccess: {
    title: 'Payment Success',
    heading: "It's great to have you!",
    message:
      'Securely connect, collaborate and celebrate from anywhere. With your 3Dmeet subscription, you can safely create and join high-quality 3D video meetings up to 25 people.',
    support: 'If you have any questions, please send us an email at',
    actionButton: 'GO TO ACCOUNT',
    altImageCheckout: 'success',
  },
  paymentCancel: {
    title: 'Cancel Payment',
    heading: 'Cancelling checkout',
    message:
      'You will not be charged since you cancelled in-between the checkout process.',
    actionButton: 'Go Back To Home',
    altImageCheckout: 'cancel',
  },
  manageAccount: {
    title: 'Manage Account',
    backArrow: '←',
    backText: 'Back',
    accountSettingsHeading: 'Account Settings',
    manageSubscriptionLabel: 'Manage Subscription',
    profileSettingsHeading: 'Profile Settings',
    updateDisplayNameButton: 'Update Display Name',
  },
  billingPortalSessionButton: {
    subscription: 'Subscription',
  },
  notFound: {
    title: '404: Not Found',
    heading: 'Not Found',
    message: "You just hit a route that doesn't exist... the sadness.",
  },
  invite: {
    title: 'Invite',
    customTitle: 'Custom Invite',
    loading: 'Loading...',
    unauthorizedHeading: 'Sign in to join the meeting',
    missingQueryParamMessage:
      'Something went wrong, please try a different invite link',
    genericErrorMessage: 'An error has occurred, please try again.',
    greeting: 'Hi {{name}}!',
    joiningMeeting: 'Joining meeting...',
  },
  beta: {
    title: 'Beta',
    heading: 'You are invited to join the 3Dmeet beta!',
    step1Heading: 'Step 1 - Join our Slack community',
    step2Heading: 'Step 2 - Sign up for beta testing',
    step3Heading: 'Step 3 - Enjoy 🚀',
    step3Body: 'Have fun! We look forward to your feedback.',
  },
  feedback: {
    paragraph1:
      'Join our <0>Slack community</0> to provide feedback and to receive support.',
    paragraph2:
      'You can provide feedback in the <0>#feedback channel</0> and ask for help in the <1>#troubleshooting channel</1>.',
  },
  instruction: {
    getStarted: 'Get Started',
    android: 'Android',
    ios: 'iOS',
    web: 'Web',
  },
  blog: {
    title: 'Blog',
    goHome: 'Go Home',
  },
  bio: {
    paragraph1:
      'Written by the 3Dmeet team, who work everyday to innovate the way the world collaborates online.',
  },
  home: {
    title: '3D Video Conferencing Software for Education and Training',
    description:
      "3Dmeet's reliable and secure video conferencing platform allows you to hold class and conduct training in immersive virtual worlds in the Metaverse!",
    keywords: '',
  },
  legal: {
    title: 'Legal',
    goHome: 'Go Home',
  },
  whoops: {
    title: 'Whoops',
    heading: 'Invalid video call name.',
    buttonText: 'Return to home screen',
  },
  support: {
    title: 'Support',
    heading: 'You can contact us if you have any questions',
    name: 'Name *',
    email: 'Email *',
    message: 'Message *',
    submit: 'Submit',
    messageSent: 'Your message has been sent',
  },
  signIn: {
    title: "3Dmeet's Account Sign In | 3D Video Calling Software",
    description:
      '3Dmeet is a one of a kind video calling service and app where your unique avatar can host meetings across our virtual world in the most renowned locations on earth.',
    heading: 'Sign In',
  },
  newMeeting: {
    title: 'New Meeting',
    loading: 'Loading...',
    unauthorizedHeading: 'Sign in to create a meeting',
    genericError: 'An error has occurred, please try again.',
    greeting: 'Hi {{name}}!',
    creatingMeeting: 'Creating meeting...',
  },
  customMeeting: {
    title: 'Custom Meeting',
    heading: 'Custom Meeting',
    loading: 'Loading...',
    unauthorizedHeading: 'Sign in to create a meeting',
    genericError: 'An error has occurred, please try again.',
    shortGenericError: 'An error has occurred.',
    confirmSaveBeforeStarting:
      'Would you like to save your meeting configs before starting the meeting?',
    saveForLater: 'Save meeting link for later',
    startNow: 'Start meeting now',
    unloadMessage: 'You have unsaved changes.',
    saveChanges: 'Save Changes',
    headingPrivileges: 'Set Meeting Privileges',
    roleNameHeading: 'Role Name',
    roleDescriptionHeading: 'Description',
    roleLinkCopy: 'Copy Link To Share',
    roleSave: 'Save',
    roleNameAssistant: 'Assistant',
    roleDescriptionAssistant:
      'An assistant is a designated aide who is given permissions to help the moderator of the meeting.',
    roleInviteLinkAssistant: 'Assistant Invite Link',
    roleNameParticipant: 'Participant',
    roleDescriptionParticipant:
      'A participant typically has limited permissions.',
    roleInviteLinkParticipant: 'Participant Invite Link',
    headingContent: 'Manage Content',
    permissions: {
      meetingEnd: 'Ability to end the meeting for everyone',
      meetingStart: 'Ability to start meeting so others can join without host',
      locationRecall: 'Ability to bring individual(s) to your location',
      voiceMuteOther: 'Ability to mute individual(s) voice',
      'voiceUnmuteNewProducer videoUnmuteNewProducer':
        "Ability to unmute one's own voice/video",
      videoMuteOther: 'Ability to mute individual(s) video',
      'videoChangePositionalMode voiceChangePositionalMode':
        'Ability to activate location-based voice',
      chatMuteOther: 'Ability to mute individual(s) chat',
      viewboardControl: 'Ability to control content shown on viewboards',
    },
    linkCopiedHost: 'Copied host meeting link',
    linkCopiedAssistant: 'Copied assistant meeting link',
    linkCopiedParticipant: 'Copied participant meeting link',
    saveMeetingSuccess: 'Successfully saved meeting settings',
  },
  contentManager: {
    uploadSuccess: '{{filename}} uploaded',
    uploadError: 'Upload error for {{filename}}: ',
    uploadContent: {
      heading: 'Upload',
      subheading: 'Upload images, videos, and other content files here',
      dropFiles: 'Drop Files to Upload',
      supportedFilesWithSize: 'Supported Files include (up to {{size}}):',
      supportedFileExtensions: '.jpg .jpeg .png .mp4',
      selectFiles: 'Select Files',
      uploadButton: 'Upload Content',
    },
    attachContent: {
      heading: 'Attached Content',
      subheading: 'Selected files for this meeting',
    },
    contentLibrary: {
      heading: 'Content Library',
      subheading: 'All available content',
    },
  },
  userProvider: {
    profileUpdateSuccess: 'You have successfully updated your profile',
    profileUpdateError: 'Could not update profile: ',
  },
  signOut: {
    error: 'An error has occurred',
    button: 'logout',
  },
  appDownload: {
    heading: 'Try it now!',
    buttonWeb: 'Web',
    buttonIOS: 'iOS',
    buttonAndroid: 'Android',
    comingSoon: 'Coming Soon!',
  },
  brand: {
    altImageLogo: '3Dmeet logo',
    subtitle: 'A Metaverse-Driven Platform for Education and Training',
  },
  menuCollapse: {
    pricing: 'Pricing',
  },
  footerSection: {
    buttonHome: 'Home',
    buttonAbout: 'About',
    buttonProducts: 'Other Products',
    copyright: '© {{date}} Virtual Immersive Educational Worlds, Inc.',
    contact: 'Contact us for pricing <0>{{email}}</0>',
  },
  legalPost: {
    lastUpdated: 'Last updated on {{date}}',
  },
  shareLink: {
    heading: "Here's the link to your meeting",
    message:
      'Copy this link and send it to people you want to meet with. Be sure to save it so you can use it later, too.',
    copiedLink: 'Copied meeting link',
    signInRequired: 'Please sign in to get a meeting link to share.',
    error: 'An error has occurred. Try again.',
  },
}

export default English
