import { gql } from '@apollo/client'

export const GET_USER_MEETING_DYNAMIC_LINK = gql`
  query getUserMeetingDynamicLink($meetingId: ID!) {
    getUserMeetingDynamicLink(meetingId: $meetingId)
  }
`

export const GET_MEETING_DYNAMIC_LINK = gql`
  query meetingDynamicLink($sub: ID!, $aud: String!, $permissions: String!) {
    meetingDynamicLink(sub: $sub, aud: $aud, permissions: $permissions)
  }
`

export const GET_CUSTOM_MEETING_INVITE_LINK = gql`
  query customMeetingInviteLink($aud: String!, $permissions: String!) {
    customMeetingInviteLink(aud: $aud, permissions: $permissions)
  }
`

export const CHECK_IF_MEETING_EXISTS = gql`
  query checkIfMeetingExists($meetingId: ID!) {
    checkIfMeetingExists(meetingId: $meetingId) {
      id
      hostId
    }
  }
`

export const CREATE_MEETING = gql`
  mutation createMeeting($content: [ContentInput]) {
    createMeeting(content: $content) {
      id
      hostLink
      inviteLink
    }
  }
`

export const GET_MEETING = gql`
  query getMeeting($meetingId: ID!) {
    getMeeting(meetingId: $meetingId) {
      id
      hostId
      hostLink
      inviteLink
      config {
        access {
          invite {
            inviteRequestURL
          }
        }
        content {
          id
          type
        }
        limits {
          duration
          users
        }
        locations {
          id
          name
          resourceID
        }
        startingLocation
      }
    }
  }
`

// TODO: Implement timestamp scalar support for modifiedAt
export const UPDATE_MEETING = gql`
  mutation updateMeeting($meetingId: ID!, $content: [ContentInput]) {
    updateMeeting(meetingId: $meetingId, content: $content) {
      id
      hostId
      config {
        access {
          invite {
            inviteRequestURL
          }
        }
        content {
          id
          type
        }
        limits {
          duration
          users
        }
        locations {
          id
          name
          resourceID
        }
        startingLocation
      }
    }
  }
`

export const SET_USER = gql`
  mutation setUser(
    $displayName: String
    $appearance: Appearance
    $photoURL: String
  ) {
    setUser(
      displayName: $displayName
      appearance: $appearance
      photoURL: $photoURL
    ) {
      uid
      timestamp
    }
  }
`

export const GET_SUBSCRIPTION_INFO = gql`
  query getSubscriptionInfo($uid: ID!) {
    getSubscriptionInfo(uid: $uid) {
      tier
    }
  }
`

export const CREATE_SUBSCRIPTION_SESSION = gql`
  mutation createSubscriptionSession($stripePriceNickName: String!) {
    createSubscriptionSession(stripePriceNickName: $stripePriceNickName)
  }
`

export const GET_BILLING_PORTAL_SESSION = gql`
  query {
    getBillingPortalSession
  }
`

export const ADD_CONTENT = gql`
  mutation addContent($input: AddContentInput!) {
    addContent(input: $input) {
      content {
        id
        ownerId
        name
        description
        mimeType
        uploadedAt
        modifiedAt
        size
        hash
        path
        status
      }
      upload {
        url
        expires
      }
    }
  }
`

export const DELETE_CONTENT = gql`
  mutation deleteContent($input: QueryIdInput!) {
    deleteContent(input: $input) {
      contentId
    }
  }
`

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($input: DeleteCollectionInput!) {
    deleteCollection(input: $input) {
      collectionId
    }
  }
`

export const GET_LIBRARY = gql`
  query getLibrary {
    getLibrary {
      library {
        content {
          content {
            id
            ownerId
            name
            description
            mimeType
            uploadedAt
            modifiedAt
            size
            hash
            path
            status
          }
          download {
            url
            expires
          }
        }
        collections {
          id
          ownerId
          name
          description
          createdAt
          modifiedAt
          content {
            content {
              id
              ownerId
              name
              description
              mimeType
              uploadedAt
              modifiedAt
              size
              hash
              path
              status
            }
            download {
              url
              expires
            }
          }
        }
      }
    }
  }
`
