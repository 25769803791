import { LOAD_USER } from './UserActions'

const UserReducer = (state, action) => {
  switch (action.type) {
    case LOAD_USER:
      return {
        user: action.payload,
        isAuthInitialized: true,
      }
    default:
      return state
  }
}

export default UserReducer
