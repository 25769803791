import React, { useReducer, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import firebase from 'gatsby-plugin-firebase'
import { useMutation } from '@apollo/client'

import UserContext from './UserContext'
import UserReducer from './UserReducer'
import { LOAD_USER } from './UserActions'
import { SET_USER } from 'services/graphql'
import { toast } from 'react-toastify'

const UserProvider = ({ children }) => {
  const initialState = {
    user: null,
    isAuthInitialized: false,
  }

  const { t } = useTranslation('userProvider')
  const [state, dispatch] = useReducer(UserReducer, initialState)

  const [
    setUser,
    { loading: setUserLoading, data: setUserData, error: setUserError },
  ] = useMutation(SET_USER)

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await dispatch({
          type: LOAD_USER,
          payload: user,
        })
      } else {
        await dispatch({
          type: LOAD_USER,
          payload: null,
        })
      }
    })

    return () => unsubscribe()
  }, [])

  const setUserInFirestore = useCallback(
    async (user) => {
      // TODO: Implement incremental back off instead
      if (!setUserError) {
        try {
          await setUser({
            variables: {
              displayName: user.displayName ? user.displayName : null,
              photoURL: user.photoURL ? user.photoURL : null,
            },
          })
        } catch (error) {
          console.error('An error has occurred saving user')
        }
      }
    },
    [setUser, setUserError],
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        state.user &&
        state.user.ya &&
        !setUserData &&
        !setUserLoading &&
        window.location.pathname !== process.env.GATSBY_SITE_SIGN_IN_PATH
      ) {
        setUserInFirestore(state.user)
      }
    }
  }, [
    state.user,
    setUser,
    setUserData,
    setUserLoading,
    setUserError,
    setUserInFirestore,
  ])

  const updateUserProfile = useCallback(
    async (displayName) => {
      try {
        // TODO: Update system so these don't need to be kept in sync
        await setUserInFirestore({ displayName })
        await firebase.auth().currentUser.updateProfile({ displayName })
        toast.success(t('profileUpdateSuccess'))
      } catch (err) {
        console.error('Could not update profile: ', err)
        toast.error(t('profileUpdateError'))
      }
    },
    [setUserInFirestore, t],
  )

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        isAuthInitialized: state.isAuthInitialized,
        updateUserProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
