import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18n/i18n'

import UserProvider from 'context/user/UserProvider'
import ApolloProviderWrapper from './src/ApolloProviderWrapper'

// firebase
import 'firebase/auth'

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProviderWrapper>
        <UserProvider>{element}</UserProvider>
      </ApolloProviderWrapper>
    </I18nextProvider>
  )
}
