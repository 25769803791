const Spanish = {
  landingSection: {
    heading1: 'Aburrido con tu aula de aprendizaje en línea? ',
    heading2: 'Mucho mejor reunirse en la playa.',
    paragraph1:
      'Despídete del aburrimiento and mantén a tus estudiantes enfocados y entretenidos en una playa virtual o en más de 40 magnificas ubicaciones realísticas del Metaverso.',
    paragraph2: 'VEA NUESTRO EJEMPLO DE LA PLAYA VIRTUAL EN HAWAII!',
    youtubeIdHawaii: 'prp7Nq-uum4',
    youtubeTitleHawaii:
      'Deja que la aventura comience con 3Dmeet | Plataforma de reunión virtual',
    altImageScreen: 'Pantalla 3Dmeet',
  },
  aboutSection: {
    everyDevice: 'Cada dispositivo.',
    everyWalkOfLife: 'Todos los ambitos de la vida.',
    paragraph1:
      'No importa quién sea usted o qué dispositivo use, lo tenemos cubierto. Intuitivo y sencillo, los niños y los adultos de cualquier edad se conectan sin esfuerzo.',
    learnMore: 'Aprende más',
    heading2: '¡La educación nunca ha sido tan divertida!',
    subheading2:
      'Social · Interactivo · Fácil · Atractivo · Un soplo de aire fresco',
    leftFeatureListHeading: 'Sí, hacemos todo eso.',
    microphoneAndCamera: 'Compartir micrófono y cámara',
    screenSharing: 'Compartir pantalla',
    chat: 'Chat',
    fileShare: 'Recurso compartido de archivos',
    rightFeatureListHeading: 'Y nosotros también hacemos esto!',
    explore: 'Explore más de 30 entornos virtuales',
    customizeAvatar: 'Personaliza tu propio personaje',
    viewboards: 'Presentar videos, imagenes y archivos pdf en el mundo',
    voiceLocation: 'Capacidad de voz cercana o voz global',
    hostPermissions: 'Funciones especiales para anfitriones',
    streaming: 'Capacidad de transmisión - NUEVO',
    youtubeIdLearnMore: 'XYf2FKtYBZs',
    youtubeTitleLearnMore: '3Dmeet: la solución de conferencias mas completa',
    altImagePeople: 'Varias personas',
    altImageComputer: 'Computadora',
    altCheckmark: 'Marca de verificación',
  },
  callToActionSection: {
    heading1: '¡Siente el agrado de socializar en su salón de clases en línea!',
    tryButton: 'Prueba 3Dmeet ahora',
    altImageGirl: 'Chica feliz',
  },
  pricingPlans: {
    tierFree: 'GRATIS',
    tierDescriptionFree: 'Este es un plan de conferencias gratuito',
    tierPriceFree: 'GRATIS',
    virtualWorldsWithParticipants:
      '{{worlds}} mundos virtuales con un máximo de {{participants}} participants',
    virtualWorlds:
      '{{worlds}} mundos virtuales para la capacitación y la educación',
    tierFeaturesFree: [
      '{{virtualWorlds}}',
      'Capacidad de escritorio y móvil',
      'Avatares personalizables, cámara, pantalla compartida y más',
      'Chat en vivo basado en texto',
      'Visores multimedia',
      'Llamadas limitadas a 40 minutos',
    ],
    getStarted: 'EMPEZAR',
    tierPro: 'PRO',
    tierDescriptionPro: 'Este es un plan de conferencias profesionales',
    tierFeaturesPro: [
      'Incluye todo lo de la version GRATIS, más:',
      '{{virtualWorlds}}',
      'Llamadas ilimitadas',
      'Voz basada en proximidad',
      'URL personalizable',
      'Posibilidad de grabar sesión',
    ],
    tierMonthPricePro: 'o {{price}} mensuales',
    tierAnnualPricePro: '{{price}} mes ANUALMENTE',
    tierAnnualSavingsPro: 'Ahorre {{discount}}% by comprando anualmente',
    manage: 'ADMINISTRAR SUSCRIPCION',
    buyAnnual: 'COMPRAR PLAN ANUALL',
    buyMonthly: 'COMPRAR MENSUALMENTE',
    tierPremium: 'PREMIUM',
    tierDescriptionPremium: 'Este es un plan de conferencias premium',
    tierFeaturesPremium: [
      '{{virtualWorlds}}',
      'Hasta 30 participantes por sesión',
      'Reuniones ilimitadas (hasta 5 sesiones simultáneas)',
      'Atención de soporte al cliente y capacitación',
      'Marca y personalización con logotipos y pancartas de los clientes.',
      'Sistema de proximidad de voz',
      'URL personalizable',
      'Posibilidad de grabar la sesión',
    ],
    tierMonthPricePremium: '{{price}}/mes',
    tierAnnualPricePremium: ' {{price}}/año',
    tierAnnualSavingsPremium: 'Ahorre un  {{discount}}% comprando anualmente',
    manageSubscription: 'ADMINISTRAR SUSCRIPCION',
    tierEvents: 'INSTITUCIÓN',
    tierFeaturesEvents: [
      'Cuenta con todo lo que tiene PREMIUM mas:',
      'Realice foros educativos y eventos con toda la institución.',
      'Número ilimitado de sesiones',
      'Transmita los discursantes principales y celebre logros obtenidos con nuestra función de transmisión en vivo de YouTube',
      'Póngase en contacto con el equipo de soporte de 3Dmeet para conocer los precios',
    ],
    contactSales: 'CONTACTO CON VENTAS',
    error: 'Ha ocurrido un error',
    unlimitedParticipants: 'participantes ilimitados',
  },
  newMeetingButton: {
    newMeeting: 'Nueva reunión',
  },
  newMeetingOptionsModal: {
    createMeeting: 'Crea una reunión para más tarde',
    startMeeting: 'Iniciar una reunión instantánea',
    customMeeting: 'Crea una reunión personalizada',
  },
  joinMeetingForm: {
    enterCode: 'Ingresa el Código o Enlace',
    button: 'Unirse',
  },
  footer: {
    allRightsReserved: 'Reservados todos los derechos',
    codeOfConduct: 'Código de Conducta',
    termsOfService: 'Términos del Servicio',
    privacyPolicy: 'Política de Privacidad',
  },
  navbar: {
    pricing: 'Precios',
    account: 'Cuenta',
    signIn: 'Registrarse',
  },
  account: {
    title: 'Cuenta',
    heading: 'Cuenta',
    loading: 'Cargando',
    welcome: 'Bienvenidos {{name}}',
    logout: 'cerrar sesión',
    joinMeetingLabel: 'Unirse a una reunión',
    joinMeetingButton: 'Unirse',
    joinMeetingInput: 'Introduzca el código',
    startMeetingLabel: 'Iniciar una nueva reunión',
    manageAccountLabel: 'Administrar cuenta',
    manageAccountButton: 'Cuenta',
    invalidMeetingError: 'Ha ocurrido un error.',
    genericError: 'No se pudo encontrar la reunión a la que intentas unirte.',
  },
  paymentSuccess: {
    title: 'Pago Exitoso',
    heading: 'Es genial tenerte!',
    message:
      'Conéctese, colabore y celebre de forma segura desde cualquier lugar. Con su suscripción a 3Dmeet, puede crear y unirse de forma segura a videoconferencias 3D de alta calidad de hasta 25 personas.',
    support: 'Si tiene alguna pregunta, envíenos un correo electrónico a',
    actionButton: 'IR A LA CUENTA',
    altImageCheckout: 'success',
  },
  paymentCancel: {
    title: 'Cancelar el pago',
    heading: 'Cancelando el pago',
    message: 'No se le cobrará ya que canceló entre el proceso de pago.',
    actionButton: 'Vuelve al Comienzo',
    altImageCheckout: 'cancel',
  },
  manageAccount: {
    title: 'Administrar cuenta',
    backArrow: '←',
    backText: 'Atrás',
    accountSettingsHeading: 'Configuraciones de la cuenta',
    manageSubscriptionLabel: 'Administrar suscripción',
    profileSettingsHeading: 'Configuración de perfil',
    updateDisplayNameButton: 'Actualizar el nombre que se muestra',
  },
  billingPortalSessionButton: {
    subscription: 'Suscripción',
  },
  notFound: {
    title: 'No encontrado',
    heading: 'No encontrado',
    message: 'Acabas de llegar a una ruta que no existe ... te perdiste.',
  },
  invite: {
    title: 'Invitar',
    customTitle: 'Invitación personalizada',
    loading: 'Cargando...',
    unauthorizedHeading: 'Iniciar sesión para unirse a la reunión',
    missingQueryParamMessage:
      'Algo salió mal, intente con un enlace de invitación diferente',
    genericErrorMessage: 'Ha ocurrido un error. Por favor intente otra vez.',
    greeting: 'Hola {{name}}!',
    joiningMeeting: 'Uniéndose a la reunión...',
  },
  beta: {
    title: 'Beta',
    heading: 'Estas invitado a unirte a la versión beta de 3Dmeet!',
    step1Heading: 'Paso 1 - Unete a nuestra comunidad de Slack',
    step2Heading: 'Paso 2 - Regístrate para la prueba beta',
    step3Heading: 'Paso 3 - Disfruta 🚀',
    step3Body: 'Que te diviertas! Esperamos sus comentarios.',
  },
  feedback: {
    paragraph1:
      'Unete a nuestra <0>comunidad de Slack</0> para proporcionar comentarios y recibir asistencia.',
    paragraph2:
      'Puedes proporcionar comentarios en el <0>#feedback channel</0> y solicitar ayuda en el <1>#troubleshooting channel</1>.',
  },
  instruction: {
    getStarted: 'Empezar',
    android: 'Androide',
    ios: 'iOS',
    web: 'Web',
  },
  blog: {
    title: 'Blog',
    goHome: 'Vuelve al inicio',
  },
  bio: {
    paragraph1:
      'Escrito por el equipo de 3Dmeet, que trabaja todos los días para innovar la forma en que el mundo colabora en línea.',
  },
  home: {
    title:
      'Programa de video-conferencias 3D para la educación y la capacitación',
    description:
      'La plataforma 3DMeet de video-conferencias es confiable, segura y le permite realizar clases y capacitaciones en el Metaverso!',
    keywords: '',
  },
  legal: {
    title: 'Legal',
    goHome: 'Vuelve al inicio',
  },
  whoops: {
    title: 'Carambas',
    heading: 'Nombre de videollamada no es válido',
    buttonText: 'Volver a la pantalla de inicio',
  },
  support: {
    title: 'Apoyo',
    heading: 'Puede contactarnos si tiene preguntas',
    name: 'Nombre *',
    email: 'Email *',
    message: 'Mensaje *',
    submit: 'Entregar',
    messageSent: 'Tu mensaje ha sido enviado',
  },
  signIn: {
    title: 'Iniciar sesión',
    description:
      '3Dmeet is a one of a kind video calling service and app where your unique avatar can host meetings across our virtual world in the most renowned locations on earth.',
    heading: 'Iniciar sesión',
  },
  newMeeting: {
    title: 'Nueva reunión',
    loading: 'Cargando...',
    unauthorizedHeading: 'Iniciar sesión para crear una reunión',
    genericError: 'Ha ocurrido un error. Por favor intente otra vez.',
    greeting: 'Hola {{name}}!',
    creatingMeeting: 'Creando una reunión...',
  },
  customMeeting: {
    title: 'Reunión personalizada',
    heading: 'Reunión personalizada',
    loading: 'Cargando...',
    unauthorizedHeading: 'Iniciar sesión para crear una reunión',
    genericError: 'Ha ocurrido un error. Por favor intente otra vez.',
    shortGenericError: 'Ha ocurrido un error.',
    confirmSaveBeforeStarting:
      'Le gustaría guardar las configuraciones de su reunión antes de comenzar la reunión?',
    saveForLater: 'Guardar el enlace de la reunión para más tarde',
    startNow: 'Empiece a reunirse ahora',
    unloadMessage: 'Tiene cambios sin guardar',
    saveChanges: 'Guardar cambios',
    headingPrivileges: 'Establecer privilegios de reunión',
    roleNameHeading: 'Nombre de rol',
    roleDescriptionHeading: 'Descripción',
    roleLinkCopy: 'Copiar enlace para compartir',
    roleSave: 'Salvar',
    roleNameAssistant: 'Asistente',
    roleDescriptionAssistant:
      'Un asistente es un ayudante designado que tiene permisos para ayudar al moderador de la reunión.',
    roleInviteLinkAssistant: 'Enlace de invitación al asistente',
    roleNameParticipant: 'Partícipante',
    roleDescriptionParticipant:
      'El participante normalmente tiene permisos limitados.',
    roleInviteLinkParticipant: 'Enlace de invitación de participante',
    headingContent: 'Administrar contenido',
    permissions: {
      meetingEnd: 'Posibilidad de finalizar la reunión para todos',
      meetingStart:
        'Posibilidad de comenzar a reunirse para que otros puedan unirse sin anfitrión',
      locationRecall: 'Capacidad para traer personas a su ubicación',
      voiceMuteOther: 'Capacidad para silenciar la voz de las personas',
      'voiceUnmuteNewProducer videoUnmuteNewProducer':
        'Habilidad para activar su propia voz / video',
      videoMuteOther:
        'Posibilidad de silenciar el video de una (s) persona (s)o',
      'videoChangePositionalMode voiceChangePositionalMode':
        'Posibilidad de activar voz con base en la ubicación',
      chatMuteOther: 'Posibilidad de silenciar el chat de una (s) persona (s)',
      viewboardControl:
        'Capacidad para controlar el contenido que se muestra en las pantallas de visualizacion',
    },
    linkCopiedHost: 'Enlace de la reunión del organizador copiado',
    linkCopiedAssistant: 'Enlace de reunión de asistente copiado',
    linkCopiedParticipant: 'Enlace de reunión de participante copiado',
    saveMeetingSuccess: 'Configuración de la reunión guardada exitosamente',
  },
  contentManager: {
    uploadSuccess: '{{filename}} Subido',
    uploadError: 'Error de carga para  {{filename}}: ',
    uploadContent: {
      heading: 'Subir',
      subheading: 'Sube imágenes, videos y otros archivos de contenido aquí',
      dropFiles: 'Soltar archivos para cargar',
      supportedFilesWithSize:
        'Los archivos admitidos incluyen (hasta {{size}}):',
      supportedFileExtensions: '.jpg .jpeg .png .mp4',
      selectFiles: 'Selecciona archivos',
      uploadButton: 'Cargar contenido',
    },
    attachContent: {
      heading: 'Contenido adjunto',
      subheading: 'Archivos seleccionados para esta reunión',
    },
    contentLibrary: {
      heading: 'Biblioteca de contenido',
      subheading: 'Todo el contenido disponible',
    },
  },
  userProvider: {
    profileUpdateSuccess: 'Has actualizado correctamente tu perfil',
    profileUpdateError: 'No se pudo actualizar el perfil:',
  },
  signOut: {
    error: 'Ha ocurrido un error',
    button: 'cerrar sesión',
  },
  appDownload: {
    heading: 'Pruebalo ahora!',
    buttonWeb: 'Web',
    buttonIOS: 'iOS',
    buttonAndroid: 'Androide',
    comingSoon: 'Llegara Pronto!',
  },
  brand: {
    altImageLogo: 'Logotipo de 3Dmeet',
    subtitle:
      'Una plataforma para la educación y capacitación, en el Metaverso.',
  },
  menuCollapse: {
    pricing: 'Precios',
    account: 'Cuenta',
    signIn: 'Iniciar sesión',
  },
  footerSection: {
    buttonHome: 'Inicio',
    buttonAbout: 'Acerca de',
    buttonProducts: 'Otros productos',
    copyright: '© {{date}} Virtual Immersive Educational Worlds, Inc.',
    contact: 'Contáctenos para conocer los precios <0>{{email}}</0>',
  },
  legalPost: {
    lastUpdated: 'Ultima actualización en {{date}}',
  },
  shareLink: {
    heading: 'Aquí está el enlace para su reunión',
    message:
      'Copie este enlace y envíelo a las personas con las que desea reunirse. Asegúrese de guardarlo para que pueda recordarlo y usarlo más tarde.',
    copiedLink: 'Enlace de reunión copiado',
    signInRequired:
      'Inicie sesión para obtener un enlace de reunión para compartirlo.',
    error: 'Ha ocurrido un error. Intente otra vez.',
  },
}

export default Spanish
